.modalFooterClassName {
  border: none !important;
  display: block !important;
}

.EditSubscriptionHeading {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

.modalBody-class-Name {
  padding: 10px 40px !important;
}

body[dir="rtl"] .EditSubscriptionHeading {
  font-size: 30px !important;
  font-weight: 500 !important;
  color: #6172d6;
}

.EditSubscriptionSubHeading {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

body[dir="rtl"] .EditSubscriptionSubHeading {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #6172d6;
}

.Data {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #5a5a5a !important;
}

.closeButtonSubscriptionEdit {
  padding: 12px 30px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .closeButtonSubscriptionEdit {
  padding: 12px 30px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}

.closeButtonSubscriptionEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"]
  .closeButtonSubscriptionEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}

.UpdateButtonSubscriptionEdit {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .UpdateButtonSubscriptionEdit {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

.UpdateButtonSubscriptionEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

body[dir="rtl"]
  .UpdateButtonSubscriptionEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

.licenseTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.licenseTable th,
.licenseTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.licenseTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
  color: black;
}

.Data {
  font-size: 16px;
  color: #333;
}
