.packagecard {
  position: relative;
  border-radius: 4px;
  background-color: #fff !important;
  padding: 0 14px;
  top: 15px !important;
  height: 580px;
  min-width: 400px;
  /* width: 400px; */
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .packagecard {
  position: relative;
  border-radius: 4px;
  background-color: #fff !important;
  padding: 0 14px;
  top: 15px !important;
  height: 580px;
  min-width: 400px;
  margin-right: 11px;
  /* width: 400px; */
  border: 1px solid #e1e1e1 !important;
}

.package-icon {
  position: absolute;
  top: -5px;
  left: 10px;
}

.package-card-title {
  display: flex;
  justify-content: center !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #6172d6 !important;
  margin-top: 60px;
  text-align: center;
}

.InputNumber-class {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

body[dir="rtl"] .InputNumber-class {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
  margin-right: 16px;
}

.InputNumber-class-two {
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
  margin-left: -7px;
}

.month-year-text {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #a2a2a2 !important;
  margin-left: 10px !important;
  margin-top: 5px;
}

.selected-option-box {
  position: relative;
  margin-top: 10px !important;
  width: 97% !important;
  height: 37px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: start;
  font-size: 16px;
  color: #5a5a5a !important;
  font-weight: 500 !important;
  padding: 6px 14px;
}

.cross-icon {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 9px;
}

body[dir="rtl"] .cross-icon {
  position: absolute !important;
  cursor: pointer;
  right: auto;
  left: 10px !important;
  top: 9px;
}

.selected-options-container {
  margin-top: 5px;
  max-height: 35vh; /* Set the maximum height */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
}

.create-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  border: 3px dashed #6172d6;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  top: 200px;
  right: -10px;
  position: relative;
}

.Plus-Icon {
  position: absolute;
  font-size: 110px;
  font-weight: 300;
  color: #6172d6 !important;
  top: -22px;
}

.create-heading {
  position: absolute;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
  top: 90px;
  text-align: center;
}

.card-cross-icon {
  position: absolute;
  top: -11px;
  right: -14px;
  cursor: pointer;
}

.Specific-width-scroller {
  display: flex;
  justify-content: start;
  width: 120% !important;
  max-width: 100vw !important;
  gap: 20px;
  height: 620px !important;
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.Specific-width-scroller-without {
  display: flex;
  justify-content: center;
  width: 120% !important;
  max-width: 100vw !important;
  gap: 20px;
  height: 620px !important;
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.update-class-btn {
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 30px !important;
  border: 1px solid #e1e1e1;
}

.update-class-btn:hover {
  border: 1px solid #e1e1e1 !important;
}

.feature-searching-text {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #e1e1e1 !important;
  text-align: center;
  position: absolute;
  bottom: 120px;
  left: 80px;
}

.give-zindexing {
  z-index: 1 !important;
  background: #f6fafd;
  height: 81.5vh;
}

.text-column-empty-state-select {
  display: flex;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  font-size: 24px;
  margin-top: 100px;
  font-weight: 700;
  color: #e1e1e1 !important;
  text-transform: uppercase !important;
}

body[dir="rtl"] .text-column-empty-state-select {
  display: flex;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  font-size: 24px;
  margin-top: 100px;
  font-weight: 700;
  color: #e1e1e1 !important;
  text-transform: uppercase !important;
}
