.screens-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px !important;
  background: #fff;
  height: auto;
  padding: 35px 22px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin: 5px -60px;
}

body[dir="rtl"] .screens-card {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid #d3d3d3 !important;
  height: auto;
  width: 100%;
  padding: 20px;
  border-radius: 4px !important;
}

.heading-1 {
  color: #6172d6 !important;
}

.heading-2 {
  color: #4adede !important;
}

.login-screen-headings {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: flex-start;
  align-items: flex-start;
}

.login-screen-headings h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 100%;
  white-space: nowrap;
  z-index: 1;
}

.column-class-heading-login {
  align-items: center !important;
}

.column-class-heading-login h1 {
  font-size: 57px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .column-class-heading-login h1 {
  font-size: 57px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .column-class-heading-login {
  align-items: center !important;
  left: auto !important;
}

.round-circle-class {
  position: fixed !important;
  bottom: -185px !important;
  right: -101px !important;
  transform: rotate(0deg) !important;
  z-index: 1px !important;
  width: 37rem;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;
}

body[dir="rtl"] .round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  left: -10% !important;
  right: auto !important;
  transform: rotate(-15deg) !important;
  z-index: 1px !important;
}

.forgotpassword-round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  right: -100px !important;
  transform: rotate(-25deg) !important;
}

body[dir="rtl"] .forgotpassword-round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  left: -10% !important;
  right: auto !important;
  transform: rotate(-25deg) !important;
}

.phone-notification-round-circle-class {
  position: fixed !important;
  bottom: -228px !important;
  right: -187px !important;
  transform: rotate(-57deg) !important;
  z-index: 1px !important;
  width: 35rem;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;
}

body[dir="rtl"] .phone-notification-round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  left: -10% !important;
  right: auto !important;
  transform: rotate(-35deg) !important;
  z-index: -1 !important;
}

.VerificationCode-round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  right: -100px !important;
  transform: rotate(-45deg) !important;
  z-index: -1 !important;
}

body[dir="rtl"] .VerificationCode-round-circle-class {
  position: fixed !important;
  bottom: -140px !important;
  left: -10% !important;
  right: auto !important;
  transform: rotate(-45deg) !important;
  z-index: -1 !important;
}

.phone-verification-image {
  position: fixed !important;
  right: 400px !important;
  z-index: 10 !important;
}

body[dir="rtl"] .phone-verification-image {
  position: fixed !important;
  left: 400px !important;
  right: auto !important;
  z-index: 10 !important;
}

.phone-notification-verification-image {
  position: fixed !important;
  right: 400px !important;
  top: 100px !important;
  z-index: 10 !important;
}

body[dir="rtl"] .phone-notification-verification-image {
  position: fixed !important;
  left: 400px !important;
  right: auto !important;
  z-index: 10 !important;
}

.language-selector-class {
  padding-top: 50px;
  height: 10vh !important;
  display: flex !important;
  justify-content: end !important;
}

body[dir="rtl"] .language-selector-class {
  height: 10vh !important;
  display: flex !important;
  justify-content: end !important;
}

.login-cards-container {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  min-height: 90vh !important;
  padding-top: 50px !important;
  padding-right: 50px !important;
}

.twoFaScreen_mobileIcon {
  width: 26rem !important;
  margin-left: 200px;
}

.diskus_logo_card {
  /* width: 16rem !important; */
}
