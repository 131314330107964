.modalHeaderClassName-Invoice {
  border: none !important;
  display: block !important;
}
.modalFooterClassName-Invoice {
  border: none !important;
  padding: 40px 22px !important;
}

.modalBody-class-Name {
  padding: 10px 40px !important;
}

.trial-invoice-heading {
  font-size: 30px;
  font-weight: 600 !important;
  color: #6172d6;
}

.send-invoice-subHeading {
  font-size: 12px;
  font-weight: 600 !important;
  color: #6172d6;
}

.sendButtonOrganizationEdit {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none;
}

.sendButtonOrganizationEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #ffffff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none;
}

.cancelButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.cancelButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.flex-columns {
  display: flex !important;
  flex-direction: column !important;
}

.title-small-name {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.title-Textfield-name {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
  margin-bottom: 10px;
}

.main-organization-name {
  font-size: 16px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  margin-top: 15px !important;
}

.Cross-X {
  position: relative;
  top: -20px !important;
  right: -20px !important;
  font-size: 10px !important;
  cursor: pointer;
}
