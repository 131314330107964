.adminNavbar {
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  font-size: 14px !important;
  border-radius: none !important;
  margin: auto 0;
  padding: auto 0;
  font-weight: 600 !important;
}
body[dir="rtl"] .adminNavbar {
  font-weight: 600 !important;
}
nav.adminNavbar.navbar.navbar-expand-lg.navbar-light {
  padding: 0px 0px;
}

.dropItems4 > .dropdown-menu.show {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
}

.dropItems5 > .dropdown-menu.show {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
}

.dropItems3 > .dropdown-menu.show {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
}

.dropItems2 > .dropdown-menu.show {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
}

.dropdown-menu {
  border-radius: 2px;
}

.DiskusAdminNavBar > .dropdown-menu.show {
  padding: 0;
  top: 40px;
  left: 5px;
  width: auto;
}

body[dir="rtl"] .DiskusAdminNavBar > .dropdown-menu.show {
  /* padding: 0; */
  top: 40px !important;
  left: auto !important;
  right: 5px !important;
  width: auto;
}

.DiskusAdminNavBar > .dropdown-menu .dropdown-item.active {
  background: none;
  color: #000 !important;
}

.DiskusAdminNavBar > .dropdown-menu .dropdown-item:hover {
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%);
  color: #fff !important;
}
body[dir="rtl"] .DiskusAdminNavBar > .dropdown-menu .dropdown-item {
  font-weight: 500 !important;
}

body[dir="rtl"] .DiskusAdminNavBar > .dropdown-menu .dropdown-item:hover {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%);
  color: #fff !important;
  font-weight: 500 !important;
}

.DiskusAdminNavBar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  border: none !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.DiskusAdminNavBar .dropdown-toggle::after {
  margin-left: 0.7em;
}

.DiskusAdminNavBar a:nth-child(1) {
  color: #5a5a5a !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: none !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}
body[dir="rtl"] .DiskusAdminNavBar a:nth-child(1) {
  font-weight: 600 !important;
}

.DiskusAdminNavBar .dropdown-menu a {
  color: #5a5a5a !important;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: none !important;
}

.adminNavbar .dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 8px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  color: #5a5a5a !important;
}

.DiskusAdminNavBar > .dropdown-menu .dropdown-item {
  text-align: center;
  padding: 10px 10px;
  font-size: 13px !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #dadada !important;
  white-space: nowrap !important;
  width: 100%;
}

.selected-class {
  color: #6172d6 !important;
}
