.modalHeaderClassName-CreatePackage {
  border: none !important;
  display: block !important;
}
.modalFooterClassName-CreatePackage {
  border: none !important;
  padding: 40px 22px !important;
}

.modalBody-class-Name {
  padding: 10px 40px !important;
}

.CreatePackage-heading {
  font-size: 30px;
  font-weight: 600 !important;
  color: #6172d6;
}

.Add-Package {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none;
}

.Add-Package:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #ffffff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none;
}

.cancelButton-CreatePackage {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.cancelButton-CreatePackage:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.flex-columns {
  display: flex !important;
  flex-direction: column !important;
}

.title-CreatePackage {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.title-Textfield-CreatePackage {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
  margin-bottom: 4px;
}

.Cross-X {
  position: relative;
  top: -20px !important;
  right: -20px !important;
  font-size: 10px !important;
  cursor: pointer;
}

body[dir="rtl"] .Cross-X {
  position: relative;
  top: -20px !important;
  left: -20px !important;
  right: auto !important;
  font-size: 10px !important;
  cursor: pointer;
}

.input-color {
  width: 100px !important;
  height: 30px !important;
}

.color-input-border {
  border: 1px solid #e1e1e1;
  padding: 4px 16px;
  display: flex;
  align-items: center !important;
  margin-top: 1px;
  border-radius: 4px;
}

.package-color-text {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-right: 60px;
  font-weight: 600;
}

body[dir="rtl"] .package-color-text {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-right: 0px !important;
  margin-left: 60px !important;
  font-weight: 600;
}

.Error-message-class {
  font-size: 10px;
  font-weight: 700;
  color: crimson;
}
