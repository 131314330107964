* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat" !important; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
div {
  font-family: "Montserrat" !important;
}

body[dir="rtl"] h1,
body[dir="rtl"] h2,
body[dir="rtl"] h3,
body[dir="rtl"] h4,
body[dir="rtl"] h5,
body[dir="rtl"] h6,
body[dir="rtl"] p,
body[dir="rtl"] label,
body[dir="rtl"] div {
  font-family: "IBM Plex Sans Arabic" !important;
}

body {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000;
  line-height: 1.5;
  background-color: #f6fafd !important;
  overflow: hidden auto;
}

body[dir="rtl"] {
  font-family: "IBM Plex Sans Arabic" !important;
}

::-webkit-scrollbar {
  width: 0.45rem !important;
  border-radius: 20px !important;
  background-color: #e1e1e1 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.dropdown-countrylist .ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 3px 10px !important;
}

.ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  /* padding: 20px !important; */
}

body[dir="rtl"] .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  /* padding: 20px !important; */
}

.datepicker_input {
  padding: 0px 45px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da;
  width: 100% !important;
  height: 39px !important;
  font-weight: 500;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.datepicker_input:is(:hover, :focus, :focus-visible, :visited) {
  outline: none !important;
}

.datePickerTodoCreate2 {
  border: 1px solid #e1e1e1 !important;
  font-weight: 600 !important;
}

.userlogin_history_tableP.ant-table-wrapper .ant-table-tbody tr td {
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1 !important;
}

.Table.ant-table-wrapper .ant-table-tbody tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

td.ant-table-cell.random {
  padding: 5px 8px !important;
  text-align: center;
}

.TrialTableDashboard.ant-table-wrapper .ant-table-tbody tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

.TrialExtendedDashboard.ant-table-wrapper .ant-table-tbody tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

.EssentialTable.ant-table-wrapper .ant-table-tbody tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

.EssentialTable.ant-table-wrapper .ant-table-tbody tr td {
  border-bottom: 1px solid #e1e1e1 !important;
}

.userlogin_history_tableP.ant-table-wrapper .ant-table-thead tr {
  /* border-bottom: 1px solid #ccc !important; */
  padding: 20px !important;
}

/* :where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-layout"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-layout"] {
  background-color: #f6fafd;
} */

.cursor-pointer {
  cursor: pointer;
}

.navbar-nav {
  font-weight: 400 !important;
}

body[dir="rtl"] .navbar-nav {
  font-weight: 500 !important;
}

p.user-name {
  font-weight: 700 !important;
}

img.user-img {
  border-radius: 50%;
}

td.ant-table-cell {
  border-bottom: none !important;
}

span.Main-Title {
  color: #6172d6;
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
  font-weight: 600 !important;
}

body[dir="rtl"] span.Main-Title {
  color: #6172d6;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  font-weight: 600 !important;
}

span.Sub-Title {
  font-weight: 400 !important;
  font-size: 10px;
  color: #5a5a5a;
}

section.oops.d-flex.flex-column.align-items-center.justify-content-center {
  padding: 63px 0px;
}

span.Main-Title.UserLogin {
  color: #6172d6;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  font-weight: 700 !important;
}

span.Sub-Title {
  font-weight: 400 !important;
  font-size: 12px;
  color: #5a5a5a;
}

body[dir="rtl"] span.Sub-Title {
  font-weight: 300 !important;
  font-size: 12px;
  color: #5a5a5a;
}

span.Main-Title.Table {
  color: #6172d6;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  font-weight: 600 !important;
}

body[dir="rtl"] span.Main-Title.Table {
  color: #6172d6;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  font-weight: 500 !important;
}

span.Sub-Title.Table {
  font-weight: 400 !important;
  font-size: 10px;
  color: #5a5a5a;
}

body[dir="rtl"] span.Sub-Title.Table {
  font-weight: 300 !important;
  font-size: 10px;
  color: #5a5a5a;
}

/* Snackbar Notification CSS */
.MuiAlert-filledError.notification-error {
  width: 100% !important;
  background: #6172d6 !important;
  position: relative !important;
  box-shadow: none !important;
}

.MuiAlert-filledSuccess.notification-success {
  width: 100% !important;
  background: #6172d6 !important;
  position: relative !important;
  box-shadow: none !important;
}

body[dir="rtl"] .MuiAlert-filledSuccess.notification-success {
  width: 100% !important;
  background: #6172d6 !important;
  position: relative !important;
  box-shadow: none !important;
  right: 10px !important;
  left: auto !important;
}

.MuiSnackbar-anchorOriginTopLeft .MuiAlert-icon {
  margin-right: auto !important;
  margin-left: 12px !important;
}

body[dir="rtl"] .MuiSnackbar-anchorOriginTopLeft .MuiAlert-icon {
  margin-left: auto !important;
  margin-right: 12px !important;
}

.MuiSnackbar-anchorOriginTopLeft .MuiAlert-action {
  display: flex !important;
  align-items: center !important;
  margin-left: -8px !important;
  margin-right: auto !important;
  padding-left: auto !important;
  padding-right: 16px !important;
}

body[dir="rtl"] .MuiSnackbar-anchorOriginTopLeft .MuiAlert-action {
  display: flex !important;
  align-items: center !important;
  margin-right: -8px !important;
  margin-left: auto !important;
  padding-right: auto !important;
  padding-left: 16px !important;
}

.MuiAlert-filledSuccess.notification-email {
  width: 100% !important;
  background: #cc0000 !important;
  position: relative !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: none !important;
}

.TrialTableDashboard .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

.billingTable .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .billingTable .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .TrialTableDashboard .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

.TrialExtendedDashboard .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 2px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .TrialExtendedDashboard .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

.EssentialTable .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .EssentialTable .ant-table-thead > tr * {
  font-size: 12px !important;
  padding: 1px 0px !important;
  font-weight: 600 !important;
}

.rmdp-day.rmdp-today span {
  background-image: linear-gradient(#4bdbde, #5f7ad7) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #e1e1e1 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 8px 8px 0 0;
  /* border-collapse: collapse; */
  border-spacing: 0;
}

.eye_icon.valid-feedback {
  display: block;
  position: absolute;
  top: 85px;
  font-size: 22px;
  right: 53% !important;
  width: 30px !important;
  cursor: pointer;
}

body[dir="rtl"] .eye_icon.valid-feedback {
  display: block;
  position: absolute;
  top: 85px;
  font-size: 22px;
  right: 43% !important;
  left: 0 !important;
  width: 30px !important;
  cursor: pointer;
}

.eye_icon_newPassowrd.valid-feedback {
  display: block;
  position: absolute;
  top: 153px;
  font-size: 22px;
  right: 53% !important;
  width: 30px !important;
  cursor: pointer;
}

body[dir="rtl"] .eye_icon_newPassowrd.valid-feedback {
  display: block;
  position: absolute;
  top: 153px;
  font-size: 22px;
  right: 43% !important;
  left: 0 !important;
  width: 30px !important;
  cursor: pointer;
}

.eye_icon_ConfirmPassword.valid-feedback {
  display: block;
  position: absolute;
  top: 221px;
  font-size: 22px;
  right: 53% !important;
  width: 30px !important;
  cursor: pointer;
}

body[dir="rtl"] .eye_icon_ConfirmPassword.valid-feedback {
  display: block;
  position: absolute;
  top: 221px;
  font-size: 22px;
  right: 43% !important;
  left: 0 !important;
  width: 30px !important;
  cursor: pointer;
}

li.valid svg {
  margin-top: 5px !important;
}

li.invalid svg {
  margin-top: 5px !important;
}

span.package-icon-style {
  position: absolute !important;
  top: -11px !important;
  left: 11px !important;
  font-size: 65px !important;
}

.ant-input-number-handler-wrap {
  opacity: 1 !important; /* Make arrow always visible */
  height: 37px !important;
}

.ant-input-number-handler-up,
.ant-input-number-handler-down {
  background-color: #fff !important; /* Customize arrow background color */
  border: 1px solid #d9d9d9 !important; /* Customize arrow border */
}

/* Customize arrow icon color */
.ant-input-number-handler-up-icon,
.ant-input-number-handler-down-icon {
  color: #6172d6 !important;
}

/* Customize arrow icon size */
.ant-input-number-handler-up-icon:before,
.ant-input-number-handler-down-icon:before {
  font-size: 10px !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number-outlined {
  background: #ffffff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e1e1e1 !important;
  height: 39px !important;
  width: 90px !important;
}

/* for reduce height select */
.ReactFlagsSelect-module_selectValue__152eS,
.ReactFlagsSelect-module_selectOption__3pcgW {
  cursor: pointer;
  padding: 0 8px;
  margin: 0px 0 !important;
  white-space: nowrap;
}
.userProfileModal .modal-content {
  width: 503px !important;
  height: 447px !important;
  margin-left: 150px;
}

body[dir="rtl"] .userProfileModal .modal-content {
  width: 503px !important;
  height: 447px !important;
  margin-right: 150px;
}

/* Class for PackageDetails modal */
.PackageDetails .modal-content {
  height: auto !important;
  width: 613px !important;
  border-radius: 4px !important;
  margin-left: 50px;
}

/* Class for TrialRenew modal */
.TrialRenewClass .modal-content {
  height: 320px !important;
  width: 680px !important;
  border-radius: 4px !important;
  margin-left: 50px;
}

/* class for confirmation modal  */
.confirmationModal .modal-content {
  width: 600px !important;
  height: 170px !important;
  margin-left: 50px;
}

body[dir="rtl"] .confirmationModal .modal-content {
  width: 600px !important;
  height: 170px !important;
  margin-right: 60px;
}

/* class for update subscription modal in view organization */
.update-SUbscription .modal-content {
  height: 600px !important;
  width: 500px !important;
  border-radius: 4px !important;
  /* margin-left: 50px; */
}

/* css counter text inside */
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number-sm
  input.ant-input-number-input {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  text-align: center !important;
  margin-left: -30px !important;
  margin-top: 5px !important;
}

/* counter field extend */
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number {
  min-width: 110px !important;
  margin-left: 10px;
}

/* counter arrow css */
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-up-inner,
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  margin-inline-end: 0 !important;
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: bolder !important;
  padding: 1px 5px !important;
}

/* on hover arrow will not be expand */
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler:hover {
  height: 40%;
  border-radius: 0px !important;
}

/* for width of arrow of counter */
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex: auto !important;
  margin-left: -14px !important;
  padding-right: 3px 10px !important;
  height: 40% !important;
  border: 1px solid #e1e1e1 !important;
  color: blue !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

body[dir="rtl"]
  :where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler {
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex: auto !important;
  /* margin-right: -14px !important; */
  padding-right: 3px 10px !important;
  height: 40% !important;
  border: 1px solid #e1e1e1 !important;
  color: blue !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-up-inner
  svg,
:where(.css-dev-only-do-not-override-1drr2mu).ant-input-number
  .ant-input-number-handler-down-inner
  svg {
  display: inline-block !important;
  font-size: 17px !important;
}

/* select dropdown border select */
.Select-type .css-13cymwt-control {
  border: 1px solid #e1e1e1 !important;
}

.ant-table-wrapper.cashFLowClass
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

.ant-table-wrapper.outstandingFlow
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

.ant-table-wrapper.TrialTableDashboard
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

.ant-table-wrapper.billingTable
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

.ant-table-wrapper.TrialExtendedDashboard
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

.ant-table-wrapper.EssentialTable
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none !important;
}

/* Class for changePassword modal */
.changePassword .modal-content {
  height: 471px !important;
  width: 704px !important;
  border-radius: 4px !important;
  margin-left: 50px;
}

.swiper-slide {
  flex-shrink: 1 !important;
  height: 100% !important;
  position: relative !important;
  display: flex !important;
  padding: 0px 5px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #5a5a5a !important;
}

:where(.css-1drr2mu).ant-input-number {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 110px !important;
  height: 39px !important;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
}

:where(.css-1drr2mu).ant-input-number-sm input.ant-input-number-input {
  height: 33px !important;
  padding: 0px 7px !important;
}
:where(.css-1drr2mu).ant-input-number .ant-input-number-input {
  box-sizing: border-box;
  margin: 0;
  display: flex !important;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: inherit;
  line-height: 1.5714285714285714;
  list-style: none;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
  width: 100%;
  text-align: center !important;
  align-items: center !important;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
  outline: 0;
  transition: all 0.2s linear;
  appearance: textfield;
}

body[dir="rtl"] .custom-table .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  /* padding: 20px !important; */
}

body[dir="rtl"] .cashFLowClass .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  /* padding: 20px !important; */
}

body[dir="rtl"] .outstandingFlow .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  /* padding: 20px !important; */
}

body[dir="rtl"] .userlogin_history_tableP .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  /* padding: 20px !important; */
}

.class-table-loginhistory {
  text-align: left !important;
}

body[dir="rtl"] .class-table-loginhistory {
  text-align: right !important;
}

/* this is for when header dropdown open then on active color is this */
a.dropdown-item.dropdown-item:hover {
  background: #f9f9f9;
}

.valid span {
  font-weight: bold;
  color: #6172d6 !important;
}

body[dir="rtl"] .valid span {
  font-weight: 500;
}

.invalid span {
  color: #ff0000 !important;
  font-weight: bold;
}

body[dir="rtl"] .invalid span {
  font-weight: 500;
}

/* for notification show */
.MuiAlert-filledSuccess {
  color: #fff;
  font-weight: 500;
  background-color: #6172d6 !important;
}

/* for packages counter arrow */

:where(.css-1drr2mu).ant-input-number .ant-input-number-handler:hover {
  height: 0% !important;
}
:where(.css-1drr2mu).ant-input-number .ant-input-number-handler-up-inner svg,
:where(.css-1drr2mu).ant-input-number .ant-input-number-handler-down-inner svg {
  display: inline-block;
  color: #6172d6 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}
