.modalHeaderClassName-Invoice {
  border: none !important;
  display: block !important;
}

.modalFooterClassName-Invoice {
  border: none !important;
  padding: 25px 34px !important;
}

.modalBody-class-Name {
  padding: 10px 60px !important;
}

.send-invoice-heading {
  font-size: 30px;
  font-weight: 600 !important;
  color: #6172d6;
}

.send-invoice-subHeading {
  font-size: 11px;
  font-weight: 600 !important;
  color: #6172d6;
}

.send-invoice-subheading-2 {
  font-size: 13px;
  font-weight: 400 !important;
  color: #5a5a5a;
}

.sendButtonOrganizationEdit {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.sendButtonOrganizationEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.custom-table-row {
  display: flex;
  width: max-content;

  /* Add margin between rows if needed */
}

.custom-table-cell {
  padding: 10px; /* Add padding for better appearance */
  border: 1px solid #ddd; /* Add border styling */
  margin-left: -1px;
  width: 130px;
  min-width: 100px; /* Ensure minimum width for cells */
  text-align: center;
}

.custom-table-header {
  font-weight: 700;
  color: #5a5a5a;
}

.column-container {
  display: flex;
  flex-direction: column;
}

.all-cells {
  margin-left: -20px;
  padding: 5px 20px;
  overflow-x: auto;
  max-width: 489px; /* Fixed width */
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 415px; /* Minimum width to match the fixed width */
}
.Cross-X {
  position: relative;
  top: -20px !important;
  right: -40px !important;
  font-size: 10px !important;
  cursor: pointer;
}

body[dir="rtl"] .Cross-X {
  position: relative;
  top: -20px !important;
  left: -10px !important;
  font-size: 10px !important;
  right: auto !important;
  cursor: pointer;
}
