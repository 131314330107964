.view-organization {
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.organization-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.organization-collapse .ant-collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff !important;
  margin-top: 20px !important;
  /* border-radius: 16px !important; */
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.organization-collapse .ant-collapse-content-box {
  background-color: #fff !important;
  padding-right: 80px !important;
}

.organization-name {
  font-size: 18px;
  font-weight: bold;
}

.organization-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-item {
  font-size: 14px;
}

.edit-button,
.update-button {
  padding: 7px 20px;
  background-color: #6172d6;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.update-button {
  align-self: flex-end;
}

.update-button:hover,
.edit-button:hover {
  background-color: #6172d6;
}

.custom-icon {
  font-size: 30px !important; /* Adjust the size as needed */
  color: #5a5a5a !important;
  pointer-events: all !important;
  margin-top: 150px !important;
}

.custom-table {
  max-height: 150px !important;
  overflow-y: auto !important;
}

.above-table {
  /* margin-top: 30px !important; */
}

.custom-table .ant-table-thead > tr > th {
  border: none !important;
}

.custom-table .ant-table-thead tr {
  border: none !important;
}

/* for arrow css */
:where(.css-dev-only-do-not-override-1drr2mu).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 100px;
  display: flex;
  align-items: end;
  padding-inline-end: 12px;
}

/* for decrease padding from top and bottom and increase right to left */
:where(.css-dev-only-do-not-override-1drr2mu).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-right: 40px;
  margin-right: 10px !important;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}

body[dir="rtl"]
  :where(.css-dev-only-do-not-override-1drr2mu).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-right: 40px;
  margin-left: 10px !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}

.HeadingViewORganization {
  font-weight: bold !important;
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .HeadingViewORganization {
  font-weight: bold !important;
  font-size: 26px !important;
  color: #6172d6 !important;
  font-weight: 500 !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
  top: 13px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  left: 10px !important;
  right: auto !important;
}

.SearchablesItems {
  padding: 7px 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  align-items: center;
  gap: 35px;
  min-width: 50px; /* Minimum width */
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Searches {
  flex: 1; /* Allow text to stretch */
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CrossIcon_Class {
  cursor: pointer !important;
}

.SearchBox {
  width: 100% !important;
  height: auto !important;
  min-height: 260px !important;
  background: #ffffff !important;
  position: absolute;
  right: 0;
  z-index: 1 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .SearchBox {
  width: 523px !important;
  height: auto !important;
  min-height: 260px !important;
  background: #ffffff !important;
  position: absolute;
  right: auto !important;
  left: 0 !important;
  z-index: 1 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.UserLoginHistory_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  cursor: pointer !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.UserLoginHistory_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}
.datePicker_Container {
  width: 100% !important;
  border-radius: 4px !important;
  display: block !important;
}

.SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"]
  .SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

.SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border: none !important;
  font-weight: 500 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.inner-organization-heading-view-modal {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 400 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .inner-organization-heading-view-modal {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 400 !important;
  cursor: pointer !important;
}

.inner-sub-Heading {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .inner-sub-Heading {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.inner-sub-Heading-insidetable {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .inner-sub-Heading-insidetable {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
  top: 13px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  left: 10px !important;
  right: auto !important;
}

.SearchablesItems {
  padding: 5px 20px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  text-align: center !important;
  gap: 35px !important;
}

.Searches {
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.UserLoginHistory_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  cursor: pointer !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.UserLoginHistory_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}
.datePicker_Container {
  width: 100% !important;
  border-radius: 4px !important;
  display: block !important;
}

.cashFLowClass-infinite {
  overflow-x: hidden !important;
}

.class-main-headerColumn {
  text-align: left !important;
}

body[dir="rtl"] .class-main-headerColumn {
  text-align: right !important;
}

span.Main-Title-ViewOrganization {
  color: #6172d6;
  font-size: 35px;
  text-align: center;
  margin-top: 10px;
  font-weight: 600 !important;
}

body[dir="rtl"] span.Main-Title-ViewOrganization {
  color: #6172d6;
  text-align: center;
  margin-top: 10px;
  font-weight: 600 !important;
}

.view-organization-section {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 80px;
}
.currenrOrganizationTab {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  color: #5a5a5a;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;

}
.currenrOrganizationTab_active {
  background: #6172d6;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
}