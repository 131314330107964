.modalFooterClassName {
  border: none !important;
  display: block !important;
}

.OrganizationDetaisHeading {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

.SubHeadingsOrganizationDetails {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

body [dir="rtl"] .SubHeadingsOrganizationDetails {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #6172d6;
}

.DetialsSubHeading {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #5a5a5a;
}

.closeButtonOrganizationEdit {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}

.closeButtonOrganizationEdit:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid#e1e1e1 !important;
  border-radius: 4px !important;
}
