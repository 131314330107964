.HeadingViewORganization {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #6172d6 !important;
}

body[dir="rtl"] .HeadingViewORganization {
  font-size: 30px !important;
  font-weight: 500 !important;
  color: #6172d6 !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
  top: 13px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  left: 10px !important;
  right: auto !important;
}

.SearchBox {
  width: 100% !important;
  height: auto !important;
  min-height: 260px !important;
  background: #ffffff !important;
  position: absolute;
  right: 0;
  z-index: 1 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .SearchBox {
  width: 523px !important;
  height: auto !important;
  min-height: 260px !important;
  background: #ffffff !important;
  position: absolute;
  right: auto !important;
  left: 0 !important;
  z-index: 1 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.crossIconClass {
  cursor: pointer !important;
}

.SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"]
  .SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

.SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border: none !important;
  font-weight: 500 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.EditIcon {
  cursor: pointer !important;
}

.Export_To_Excel {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  width: 100% !important;
  padding: 8px 10px !important;
  height: 39px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .Export_To_Excel {
  background: #fff !important;
  cursor: pointer !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  width: 100% !important;
  padding: 8px 10px !important;
  height: 39px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
  font-weight: 500 !important;
}

.SearchablesItems {
  padding: 7px 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  align-items: center;
  gap: 35px;
  min-width: 50px; /* Minimum width */
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Searches {
  flex: 1; /* Allow text to stretch */
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CrossIcon_Class {
  cursor: pointer !important;
}

.user-login-history-table-paper {
  min-height: 40vh !important;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  padding: 0 20px 20px 0;
}

.inner-sub-Heading {
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .inner-sub-Heading {
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.UserLoginHistory_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  cursor: pointer !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.UserLoginHistory_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}

.datePicker_Container {
  width: 100% !important;
  border-radius: 4px !important;
  display: block !important;
}
