.global-admin-dashboard-container {
  padding: 0px 50px !important;
}

.LeftBoxDashboard {
  padding: 7px 20px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  height: auto !important;
  min-height: 80vh !important;
}

.RightBoxDashboard {
  padding: 5px 20px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  height: auto !important;
  min-height: 81.8vh !important;
}

.BillingDueHeading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
}

body[dir="rtl"] .BillingDueHeading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

/* Dropdown.module.css */

.up {
  border: 1px solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  cursor: pointer !important;
  margin-top: 5px;
  margin-left: 5px;
}

.down {
  border: 1px solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer !important;
  margin-left: 5px;
}

.dropdown-list-item {
  color: #5a5a5a !important;
  font-size: 12px !important;
  height: auto !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 3px 0;
  cursor: pointer !important;
}

.dropdown-list-item:last-child {
  border-bottom: none !important;
}

.dropdown_list {
  background: #fff !important;
  z-index: 5 !important;
  padding: 10px;
  position: absolute;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
}

.dropdown_list {
  height: 40vh !important;
  overflow-x: hidden !important;
}

.dropdown_list::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.dropdown_list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Scroller {
  height: 58vh !important;
  overflow-x: hidden !important;
}

.Scroller::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.MonthName {
  font-size: 16px !important;
  color: #5a5a5a !important;
  cursor: pointer !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-weight: 600 !important;
  margin-top: 5px;
}

.PrizeStyles {
  color: #f16b6b !important;
  font-size: 60px !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .PrizeStyles {
  color: #f16b6b !important;
  font-size: 60px !important;
  font-weight: 700 !important;
}

.PrizeSubHeading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .PrizeSubHeading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.OrgazationStatusHeading {
  color: #6172d6 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .OrgazationStatusHeading {
  color: #6172d6 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.OuterBoxPieChart {
  position: relative;
  height: auto !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.OuterBoxPieChart:is(:hover) {
  position: relative;
  height: auto !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  cursor: pointer !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
}

.OuterBoxPieChartActive {
  position: relative;
  height: auto !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  cursor: pointer !important;
  pointer-events: all !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.42);
}

.chart-container {
  position: relative;
  display: inline-block;
  pointer-events: none !important;
}

.chart-container * {
  pointer-events: auto !important;
}

.click-preventer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  cursor: not-allowed;
}

.ButtonsDashboard {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
  height: 37px !important;
  text-overflow: ellipsis; /* Add this */
  overflow: hidden; /* Add this */
  white-space: nowrap; /* Add this */
  display: inline-block;
}

body[dir="rtl"] .ButtonsDashboard {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
  height: 37px !important;
  text-overflow: ellipsis; /* Add this */
  overflow: hidden; /* Add this */
  white-space: nowrap; /* Add this */
  display: inline-block;
}

.ButtonsDashboard:is(:hover, :focus, :focus-visible, :active) {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  height: 37px !important;
}

.activeEssentialButton {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  height: 37px !important;
  text-overflow: ellipsis; /* Add this */
  overflow: hidden; /* Add this */
  white-space: nowrap; /* Add this */
  display: inline-block;
}

body[dir="rtl"] .ButtonsDashboard:is(:hover, :focus, :focus-visible, :active) {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  height: 37px !important;
  text-overflow: ellipsis; /* Add this */
  overflow: hidden; /* Add this */
  white-space: nowrap; /* Add this */
  display: inline-block;
}

.ButtonsDashboard-Licenses {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
  height: 37px !important;
  white-space: nowrap; /* Ensure text is on one line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis if text is too long */
}

.activeEssentialButton-Licenses {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  height: 37px !important;
  white-space: nowrap; /* Ensure text is on one line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis if text is too long */
}

.Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  right: auto;
  left: 10px;
}

.dashboard-table-insidetext {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .dashboard-table-insidetext {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.dashboard-tabletext {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  white-space: nowrap !important;
  text-align: left !important;
  margin-left: -13px;
}

.dashboard-user-dates {
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.Export_To_Excel_dashboard {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 7px 15px !important;
  height: 39px !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  margin: 0 !important;
  gap: 5px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

.Export_To_Excel_dashboardTableButton {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  /* padding: 7px 15px !important; */
  height: 34px !important;
  width: 120px !important;
  display: flex !important;
  margin-left: 15px !important;
  margin-bottom: 8px;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .Export_To_Excel_dashboardTableButton {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 7px 15px !important;
  height: 39px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 15px !important;
  gap: 5px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .Export_To_Excel_dashboard {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 7px 15px !important;
  height: 39px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

.send-invoice-button {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 5px 18px;
  cursor: pointer !important;
  white-space: nowrap;
}

.send-invoice-button:hover {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 5px 18px;
  cursor: pointer !important;
  white-space: nowrap;
}

body[dir="rtl"] .send-invoice-button {
  font-weight: 600 !important;
  white-space: nowrap;
}

.send-invoice-button-disable {
  background: #fff !important;
  border: none;
  color: #a2a2a2;
  font-size: 12px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  white-space: nowrap;
}

body[dir="rtl"] .send-invoice-button-disable {
  font-weight: 600 !important;
  white-space: nowrap;
}

.ContainerClass {
  width: 98% !important;
}

.ExportBUtton {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  display: flex !important;
  gap: 5px !important;
  align-items: center !important;
  font-weight: 600 !important;
}

.ExportBUtton:is(:hover, :focus, :focus-visible, :active) {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .ExportBUtton {
  font-weight: 600 !important;
  white-space: nowrap;
  padding: 12px 17px !important;
}

.ExportBUtton-essential {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  margin-top: 10px;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  display: flex !important;
  gap: 5px !important;
  align-items: center !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.ExportBUtton-essential:is(:hover, :focus, :focus-visible, :active) {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .ExportBUtton-essential {
  font-weight: 600 !important;
  white-space: nowrap;
  padding: 12px 17px !important;
  cursor: pointer !important;
}

.SearchDataes {
  padding: 7px 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  font-weight: 700 !important;
  font-size: 9px !important;
  text-align: center !important;
  gap: 15px !important;
  width: 220px !important;
}

.Searches {
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.Search-Company {
  padding: 7px 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 5px; /* Adjust gap as needed */
  min-width: 50px; /* Minimum width */
  max-width: 100%; /* Allow maximum width based on content */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Search-Company-Searches {
  flex: 1; /* Allow text to stretch */
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CrossIcon_Class-company {
  cursor: pointer !important;
  color: #505050 !important;
  margin-left: 5px;
}

.CrossIcon_Class-users {
  cursor: pointer;
  top: 15px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .CrossIcon_Class-users {
  cursor: pointer;
  top: 15px;
  position: absolute;
  right: auto;
  left: 10px;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Extend-trial-btn {
  padding: 4px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 400 !important;
  height: 34px !important;
}

.dropdown-header-content {
  display: flex;
  align-items: center;
  width: 150px; /* Adjust as needed */
}

.CrossIcon_Class {
  cursor: pointer !important;
  color: #505050 !important;
}

.dropdown-container-companyName {
  width: 160px !important;
  position: relative !important; /* Ensure relative positioning */
}

.CompanyName-select {
  font-size: 16px !important;
  color: #5a5a5a !important;
  cursor: pointer !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-weight: 600 !important;
  display: block;
}

.up {
  border: 1px solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  cursor: pointer !important;
  margin-top: 5px;
  margin-left: 5px;
}

.down {
  border: 1px solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer !important;
  margin-left: 5px;
}

body[dir="rtl"] .down {
  border: 1px solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer !important;
  margin-right: 10px;
}

.infinite-hidden-class {
  overflow-x: hidden !important;
}

.dynamic-Tabs-scroller {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.dynamic-Tabs-swipper-class {
  display: flex;
  justify-content: center !important;
}

.class-billing-table {
  text-align: left !important;
}

body[dir="rtl"] .class-billing-table {
  text-align: right !important;
}

.inside-pie-chart {
  position: absolute !important;
  top: 43% !important;
  left: 27% !important;
  /* transform: translate(-50%, -50%) !important; */
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #5a5a5a !important;
}

.pie-value-zero {
  position: absolute !important;
  top: 43% !important;
  left: 30% !important;
  /* transform: translate(-50%, -50%) !important; */
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .inside-pie-chart {
  position: absolute !important;
  top: 43% !important;
  left: 27% !important;
  /* transform: translate(-50%, -50%) !important; */
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #5a5a5a !important;
}

.scrollContainer {
  /* display: flex;
  align-items: center;
  position: relative;
  width: 60vw; */
  display: flex;
  align-items: center;
  width: 100%;
}

.scrollButton {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10; /* Ensure buttons are above the scroll content */
}

.scrollContent {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 10px 0;
  margin: 0px 7px;
  position: relative;
}

.scrollItem {
  flex: 0 0 auto;
  margin: 0 10px;
}
.button-slides-class {
  font-size: 23px !important;
  cursor: pointer;
}

body[dir="rtl"] .button-slides-class {
  font-size: 23px !important;
  cursor: pointer !important;
  transform: rotateY(180deg) !important;
}
.emptyCircle {
  border: 40px solid #e1e1e1;
  width: 155.81px;
  height: 155.81px;
  display: block;
  border-radius: 50%;
}

.emptyCircle-empty-box {
  padding: 23px 6px !important;
}

.div-in-row-empty {
  display: flex;
  flex-direction: row !important;
}

.font-size-in-Data {
  font-size: 10px !important;
  font-weight: 600 !important;
  margin-top: 10px;
  margin-left: 5px !important;
  color: #5a5a5a !important;
}
body[dir="rtl"] .font-size-in-Data {
  font-size: 10px !important;
  font-weight: 600 !important;
  margin-right: 5px !important;
  margin-top: 10px;
  color: #5a5a5a !important;
}

.font-size-in-Data-active {
  margin-left: 15px !important;
  margin-top: 25px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .font-size-in-Data-active {
  margin-right: 15px !important;
  margin-top: 25px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}
