.forget-password-in-text {
  color: #6172d6 !important;
  width: 80%;
  font-weight: 600 !important;
  font-size: 32px !important;
  text-align: center !important;
}

body[dir="rtl"] .forget-password-in-text {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  text-align: center !important;
}

.forget-button-color {
  font-weight: 500 !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  text-transform: uppercase !important;
}

.textfield-forget-password-class {
  height: 39px !important;
  padding: 0px 8px !important;
  border: 1px solid #eee !important;
  border-radius: 4px !important;
}

.go-back-text-for-pass {
  color: #5a5a5a !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.go-back-text-for-pass:hover {
  opacity: 0.8 !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.Email-text-forget {
  font-weight: 500 !important;
  color: #6172d6 !important;
  font-size: 14px !important;
}

body[dir="rtl"] .Email-text-forget {
  font-weight: 500 !important;
  color: #6172d6 !important;
  font-size: 14px !important;
}

.forget-aesterick {
  color: #f16b6b;
}

.ErrorMessege {
  color: #f16b6b;
  font-size: 12px !important;
  font-weight: 500;
  margin: 0 !important;
  height: 15px;
}
body[dir="rtl"] .ErrorMessege {
  color: #f16b6b;
  font-size: 12px !important;
  font-weight: 500;
  margin: 0 !important;
  height: 15px;
}
