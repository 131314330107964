.sign-in-text {
  color: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  text-align: center !important;
}

.LoginButton {
  padding: 8px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.LoginButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

body[dir="rtl"] .LoginButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
}

.passwordIcon {
  position: absolute !important;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

body[dir="rtl"] .passwordIcon {
  position: absolute !important;
  right: 86%;
  left: 0px;
  cursor: pointer;
  top: 10px;
}

.Remember-password {
  display: flex !important;
  align-items: center !important;
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .Remember-password {
  font-weight: 500 !important;
}

.errorMessage-inLogin {
  margin: 0px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 500 !important;
}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 500 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0px;
  height: 13px;
  color: #f16b6b;
  font-weight: 500 !important;
}

body[dir="rtl"] .errorMessage-inLogin_hidden {
  font-weight: 500 !important;
}

.forget-paswword-text {
  cursor: pointer !important;
}

.forget-paswword-text:hover {
  color: #6172d6 !important;
  cursor: pointer !important;
}

.go-back-text-for-pass {
  color: #5a5a5a !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.go-back-text-for-pass:hover {
  opacity: 0.8 !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}
