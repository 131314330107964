.Update_Password_Heading {
  font-size: calc(32px - 20%) !important;
  user-select: none;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Update_Password_Heading {
  font-weight: 600 !important;
}

.update_password_second_heading {
  margin-top: -15px !important;
}

.Update_Password_successfull_Next_button_EmailVerify {
  /* width: 50%; */
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: calc(18px - 20%) !important;
  padding: 10px 15px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Update_Password_successfull_Next_button_EmailVerify {
  font-weight: 600 !important;
}

.Update_Password_successfull_Next_button_EmailVerify:focus-visible {
  width: 50%;
  border-radius: 4px;
  font-size: calc(18px - 20%) !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.Update_Password_successfull_Next_button_EmailVerify:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6;
}
