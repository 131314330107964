.HeadingViewORganization {
  font-weight: bold !important;
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .HeadingViewORganization {
  font-weight: bold !important;
  font-size: 26px !important;
  color: #6172d6 !important;
  font-weight: 500 !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
  top: 13px;
  position: absolute;
  left: auto;
  right: 10px;
}

body[dir="rtl"] .Search_Bar_icon_class {
  cursor: pointer;
  top: 10px;
  position: absolute;
  left: 10px !important;
  right: auto !important;
}

.SearchBox {
  width: 100% !important;
  height: auto !important;
  min-height: 210px !important;
  background: #ffffff !important;
  position: absolute;
  right: 0;
  z-index: 2 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .SearchBox {
  width: 523px !important;
  height: auto !important;
  min-height: 260px !important;
  background: #ffffff !important;
  position: absolute;
  right: auto !important;
  left: 0 !important;
  z-index: 3 !important;
  top: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.crossIconClass {
  cursor: pointer !important;
}

.SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .SearchBoxResetButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"]
  .SearchBoxResetButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

.SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

body[dir="rtl"] .SearchButton:is(:hover, :focus, :active, :focus-visible) {
  padding: 10px 25px !important;
  font-size: 12px !important;
  border: none !important;
  font-weight: 500 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.EditIcon {
  cursor: pointer !important;
}

.inner-sub-Heading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .inner-sub-Heading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-weight: 500 !important;
}

.SearchablesItems {
  /* padding: 5px 20px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  text-align: center !important;
  gap: 35px !important; */
  padding: 7px 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  align-items: center;
  gap: 35px;
  min-width: 50px; /* Minimum width */
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CrossIcon_Class {
  cursor: pointer !important;
}

.Searches {
  flex: 1; /* Allow text to stretch */
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inner-organization-heading-view-modal {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.UserLoginHistory_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  border-radius: 4px !important;
  padding: 0 10px;
  cursor: pointer !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.UserLoginHistory_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}

.datePicker_Container {
  width: 100% !important;
  border-radius: 4px !important;
  display: block !important;
}

.Height {
  height: 70vh !important;
}

.cashflow-amount-column-title {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

body[dir="rtl"] .cashflow-amount-column-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.cashOutflow-amount-column-title {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #f16b6b !important;
}

body[dir="rtl"] .cashOutflow-amount-column-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #f16b6b !important;
}

.cashflow-column-title {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .cashflow-column-title {
  color: #5a5a5a !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.cashflow-column-amount {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.oustanding-column-amount {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #f16b6b !important;
}

.top-cashflow-border {
  border-top: 1px solid #5a5a5a !important;
}

.cashflow-bottom-text {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 10px 0px !important;
}

body[dir="rtl"] .cashflow-bottom-text {
  display: flex;
  justify-content: end;
  /* flex-direction: column-reverse; */
  margin-left: 0px;
}

.total-text {
  color: #5a5a5a;
  font-size: 16px !important;
  font-weight: 600 !important;
}
body[dir="rtl"] .total-text {
  color: #5a5a5a;
  font-size: 16px !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

.total-amount-text {
  color: #6172d6;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 8px;
}

body[dir="rtl"] .total-amount-text {
  color: #6172d6;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-right: 8px;
}

.total-amount-outstanding {
  color: #f16b6b;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 8px;
}

body[dir="rtl"] .total-amount-outstanding {
  color: #f16b6b;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-right: 10px;
}

.InflowsButton {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .InflowsButton {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.InflowsButton.active {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #6172d6 !important;
  border: 1px solid #e1e1e1 !important;
  color: #fff !important;
}

body[dir="rtl"] .InflowsButton.active {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #6172d6 !important;
  border: 1px solid #e1e1e1 !important;
  color: #fff !important;
}

body[dir="rtl"] .InflowsButton:is(:focus, :active, :focus-visible) {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.OutstandingButton {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .OutstandingButton {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.OutstandingButton.active {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #6172d6 !important;
  border: 1px solid #e1e1e1 !important;
  color: #fff !important;
}

body[dir="rtl"] .OutstandingButton:is(:focus, :active, :focus-visible) {
  padding: 8px 20px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.dropdown-arrow {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.arrow-aside {
  margin-left: 10px;
}

.custom-tag {
  background-color: #6172d6 !important; /* Custom background color */
  color: white; /* Optional: set the text color */
  border: none; /* Remove border */
  margin: 0 !important; /* Remove margin */
  padding: 0 7px; /* Adjust padding as needed */
}

.cashFLowClass-infinite {
  overflow-x: hidden !important;
}
