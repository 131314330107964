.EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.signIn_heading {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 30px;
  user-select: none;
  color: #6172d6;
}

body[dir="rtl"] .signIn_heading {
  font-weight: 600 !important;
}

.Next_button_EmailVerify {
  width: 280px;
  border-radius: 1px;
}

.subscribNow_button_EmailVerify {
  width: 100% !important;
  padding: 12px 30px !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  border: none !important;
}

body[dir="rtl"] .subscribNow_button_EmailVerify {
  font-weight: 600 !important;
}
.subscribNow_button_EmailVerify:disabled {
  filter: grayscale(0.5);
}

.subscribNow_button_EmailVerify:hover {
  width: 100%;
  max-width: 310px;
  border-radius: 4px;
  background-color: #6172d6;
  border: 1px solid #6172d6;
  border: none !important;
}

.newpasswordField {
  position: relative;
}

.view_password {
  position: absolute;
}

.password_check_icon_X {
  font-size: 18px;
}

/* Auth Icon and style of heading which we are using in all auth pages */
/* Start */
.Auth_Icon {
  position: absolute;
  right: -71px;
  bottom: -130px;
  transform: rotate(27.17deg);
  /* z-index: 99999; */
}
body[dir="rtl"] .Auth_Icon {
  transform: rotate(30.17deg);
}
.Login_page_text {
  display: flex;
  margin-top: 8%;
  flex-direction: column;
  margin-left: 40px;
}

.Login_page_text h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 114%;
}

.createpassword_auth_paper {
  margin-left: 60px;
  padding: 35px 45px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

body[dir="rtl"] .createpassword_auth_paper {
  padding: 35px 45px;
  margin-left: 0px !important;
}

.heading-1 {
  color: #6172d6;
}

body[dir="rtl"] .heading-1 {
  font-weight: 600 !important;
  font-size: 70px !important;
}

body[dir="rtl"] .heading-2 {
  font-weight: 600 !important;
  font-size: 60px !important;
}
.heading-2 {
  color: #6172d6;
}

.create-password.valid-feedback {
  display: block !important;
  width: 1% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: absolute !important;
  right: 40px !important;
  top: -3px !important;
}

/* End */
.IconStyle {
  display: block !important;
  width: 1% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: absolute !important;
  right: 40px !important;
  top: 18px !important;
  cursor: pointer;
}

.PasswordCheckListstyle {
  background-color: #e6eaf7;
  padding: 12px 12px !important;
  color: #fff;
  border: #707070;
  border-radius: 4px;
}

.select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

body[dir="rtl"] .select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 80%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
}

.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin:focus,
.select-language-signin:focus-within,
.select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 80.5%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.PasswordTextField {
  position: relative !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background: #fff !important;
}
.PasswordTextField::placeholder {
  color: #171d2d !important;
  opacity: 50% !important;
}

body[dir="rtl"] .PasswordTextField::placeholder {
  font-weight: 400 !important;
}

.PasswordTextField:focus,
.PasswordTextField:focus-visible,
.PasswordTextField:hover,
.PasswordTextField:visited {
  border: 1px solid #e1e1e1 !important;
}

.passwordIcon {
  position: absolute !important;
  right: 24px;
  top: 10px;
  cursor: pointer;
}
body[dir="rtl"] .passwordIcon {
  position: absolute !important;
  right: 84%;
  left: 0px;
  top: 10px;
  cursor: pointer;
}

.Create_password_remember_check {
  font-weight: 500 !important;
  color: #5a5a5a;
  align-items: center !important;
  display: flex !important;
}

body[dir="rtl"] .Create_password_remember_check {
  font-weight: 500 !important;
}

.paragraph_password_must_have {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a;
  margin: 0 !important;
  padding: 0 !important;
}

body[dir="rtl"] .paragraph_password_must_have {
  font-weight: 600 !important;
}

.goBackPackageSelectionBtn {
  color: #000 !important;
  font-size: 12px !important;
  text-decoration: underline;
  font-weight: 400 !important;
}

.forogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.forogt_email_link a {
  color: #5a5a5a !important;
}

.ForgotPassword {
  cursor: pointer;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

.passwordTextHandler {
  background-color: #e6eaf7;
  margin-top: 5px;
  color: #fff;
  border: #707070;
  border-radius: 4px !important;
}
