.overlay {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;
  backdrop-filter: blur(10px);
  left: 0;
  z-index: 999999999;
  min-height: 100vh !important;
  overflow: hidden;
}

.overlay-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center !important;
  flex-direction: column;
  gap: 20px;
}

.overlay-content-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center !important;
  flex-direction: column;
  gap: 20px;
}

.LoadderImageclass {
  width: 100% !important;
}

.LoadderImagePanelclass {
  width: 100% !important;
}

.LoadderImageVideoScreenclass {
  width: 43% !important;
}

.overlay-content-v2 {
  margin: 0 !important;
  padding: 20px !important;
  font-weight: bold;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(255, 255, 255, 0.9); */
  z-index: 999999999999999999999999;
  max-width: 650px;
  overflow: hidden;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

/* .overlay-content h3 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
} */
.overlay-content img {
  max-width: 100%;
}

.loader-line {
  width: 250px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #6172d6;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

/* .line {
  height: 3px;
  width: 80px;
  background-color: black;
  animation: loadingline .5s infinite;
}
@keyframes loadingline {
  0% {
    transform: translateX(-120px);
  } 100% {
    transform: translateX(120px);
  }
} */
/* .overlay-content p {
  margin-top: 10px;
  color: blue;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 800;
  font-family: "Roboto";
} */
.deleteOrganizationContent {
  font-weight: bold !important;
}

.Messeges_Styles {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  width: 100% !important;
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center !important;
  flex-direction: column;
  gap: 20px;
}

.Messeges_Styles_Panel {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  width: 100% !important;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -45%);
  z-index: 999999999;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center !important;
  flex-direction: column;
  gap: 20px;
}
