.modalFooterClassName {
  border: none !important;
  display: block !important;
}

.ChangePasswordHeading {
  font-size: 30px;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

body[dir="rtl"] .ChangePasswordHeading {
  font-size: 28px !important;
  color: #6172d6 !important;
  font-weight: 500 !important;
}
.modalChangeBody-class-Name {
  padding: 10px 40px !important;
}

.modalHeader-className {
  border: none !important;
}

.modalFooterClassName {
  border: none !important;
  padding: 30px 35px !important;
}

.HeadingForPassword {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .HeadingForPassword {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #5a5a5a !important;
  font-weight: 500 !important;
}

.SubHeadingsPassword {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
  margin-bottom: 3px;
}

body[dir="rtl"] .SubHeadingsPassword {
  font-size: 10px !important;
  color: #6172d6 !important;
  font-weight: 500 !important;
}

.passwordCheckBox {
  background-color: #e6eaf7;
  padding: 0px 17px;
  border-radius: 4px;
  font-size: 12px !important ;
  margin-top: 20px !important;
}

.UpdateBtnStyles {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  border: none !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #fff !important;
}

body[dir="rtl"] .UpdateBtnStyles {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  border: none !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.UpdateBtnStyles:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  border: none !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #fff !important;
}

body[dir="rtl"] .UpdateBtnStyles:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  border: none !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.RevertBtnStyles {
  padding: 12px 30px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .RevertBtnStyles {
  padding: 12px 30px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #5a5a5a !important;
  font-weight: 500 !important;
}

.RevertBtnStyles:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .RevertBtnStyles:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #5a5a5a !important;
  font-weight: 500 !important;
}

.PasswordCheckList {
  align-items: center !important;
}
.aesterick-color {
  color: #f16b6b !important;
}
