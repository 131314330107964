.sign-in-text {
  color: #6172d6 !important;
  font-size: 30px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .sign-in-text {
  color: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 28px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.LoginButton {
  padding: 8px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

body[dir="rtl"] .LoginButton {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
}

.LoginButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

body[dir="rtl"] .LoginButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background: #6172d6 !important;
  font-size: 14px !important;
  border: none !important;
  width: 100% !important;
  font-weight: 500 !important;
}

.remember-email {
  display: flex !important;
  align-items: center !important;
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .remember-email {
  font-weight: 500 !important;
}

.inputEmailField {
  border-radius: 4px !important;
  height: 39px !important;
}

body[dir="rtl"] .inputEmailField {
  font-weight: 500 !important;
}

.inputEmailField:focus,
.inputEmailField:focus-visible {
  border: 1px solid #e1e1e1 !important;
  height: 30px;
}

.inputEmailField::placeholder {
  color: #171d2d !important;
  opacity: 50%;
}
