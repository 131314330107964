.InvocieHTMLPreview {
  height: 83vh !important;
  overflow-y: auto !important;
  margin-top: 0;
  padding: 0;
}

.modalFooterClassName-Invoice {
  /* border: none !important; */
  padding: 20px 30px !important;
  display: flex;
  justify-content: center;
}

.sendInvoice-Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.sendInvoice-Button:is(:hover, :focus, :active, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.footer-column-class {
  display: flex !important;
  justify-content: center !important;
  gap: 10px !important;
}
